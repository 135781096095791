import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { Button } from "gatsby-material-ui-components";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import formatTitle from "../../components/common/formatTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";
import Fade from "@material-ui/core/Fade";
import Layout from "../../components/layout/layout";
import Title from "./title";
import DisruptiveSoftware from "../../components/homepage/disruptiveSoftware";
import PeerReviewdPaper from "../../components/common/PeerReviewedPaper";
import Time from "./time.svg";
import TimeSuccess from "./time_success.svg";
import ArrowButton from "../../components/common/ArrowButton";
import PatentMobile from "./patentMobile";

const useStyles = makeStyles((theme) => ({
  paddingRegular: {
    padding: theme.layout.paddingRegular,
  },
  paddingRegularMobile: {
    padding: theme.layout.paddingRegularMobile,
  },
  paddingLastRow: {
    padding: "8%",
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  centered: {
    textAlign: "center",
  },
  brevet: {
    border: "solid 2px #CED6E9",
    borderRadius: 10,
    boxShadow: "6px 6px 10px rgb(67 67 68 / 11%)",
  },
  cell: {
    textAlign: "center",
    padding: theme.spacing(2),
    // minHeight: 60,
  },
  cellSingleLine: {
    lineHeight: "70px",
  },
  cellH: {
    textAlign: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.text.secondary,
    fontSize: "1.3em",
  },
  titre: {
    textAlign: "left",
    paddingLeft: theme.spacing(4),
    fontWeight: 600,
  },
  mT: {
    marginTop: theme.spacing(6),
  },
  mLT: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary.dark,
  },
  blueBtn: {
    color: "#377DFF",
  },
  cta: {
    margin: "10% auto",
    minWidth: 450,
  },
  overlay: {
    backgroundColor: "#000",
  },
}));

const PublicationsEn = ({ data, pageContext: { slug, langKey } }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));
  const contents = data.allPublicationsJson.nodes[0].publications[langKey][0];
  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item>
        <Grid container direction="column" alignItems="stretch">
          <Grid
            item
            style={{
              height: `calc(100vw / ${data.publications.childImageSharp.fluid.aspectRatio})`,
              position: "relative",
            }}
          >
            <Img fluid={data.publications.childImageSharp.fluid} fadeIn />
            <Fade in timeout={3000}>
              <Title
                text1={contents.mainTitle[0]}
                text2={contents.mainTitle[1]}
                matchesMobile={!matches}
              />
            </Fade>
          </Grid>
          <Grid
            item
            className={clsx(classes.centered, {
              [classes.paddingRegular]: matches,
              [classes.paddingRegularMobile]: !matches,
            })}
          >
            <Typography variant="h1">
              {contents.contribute.title[0]}
              <br />
              {formatTitle(contents.contribute.title[1])}
            </Typography>
            <Typography variant="h5" color="textPrimary" className={classes.mT}>
              {contents.contribute.content}
            </Typography>
            <ArrowButton
              to={`/${langKey}/contact`}
              className={classes.mLT}
              label={contents.contribute.btnLabel}
              color="primary"
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={2}
            className={clsx({
              [classes.paddingRegular]: matches,
              [classes.paddingRegularMobile]: !matches,
            })}
          >
            {contents.papers.map((p) => (
              <PeerReviewdPaper {...p} langKey={langKey} matchesMobile />
            ))}
          </Grid>
          <DisruptiveSoftware
            title1={contents.wePut.title}
            title2={
              <span>
                {contents.wePut.content1}
                <br />
                {contents.wePut.content2}
              </span>
            }
            buttons={[]}
            large
            paddingTopPrct={7}
            alignContentCenter={matches}
          />
          <Grid
            item
            container
            className={clsx(classes.centered, {
              [classes.paddingRegular]: matches,
              [classes.noPaddingBottom]: matches,
              [classes.paddingRegularMobile]: !matches,
            })}
            direction="row"
            justify="center"
          >
            <Grid item xs={matches ? 9 : 12}>
              <Typography
                variant="h1"
                style={{ textAlign: matches ? "center" : "left" }}
              >
                {formatTitle(contents.patents.title)}
              </Typography>
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.mT}
              >
                {contents.patents.content}
              </Typography>
            </Grid>
            {matchesMobile &&
              contents.patents.items.map((p) => <PatentMobile {...p} />)}
            {!matchesMobile && (
              <Grid
                item
                container
                direction="column"
                spacing={2}
                xs={12}
                className={classes.mT}
              >
                <Grid item container direction="row" spacing={4}>
                  <Grid item xs={5}>
                    <div className={classes.cellH}>Name</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className={classes.cellH}>{"Date of submission"}</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={classes.cellH}>N°</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={classes.cellH}>Status</div>
                  </Grid>
                </Grid>
                {contents.patents.items.map((b) => (
                  <Grid item className={classes.brevetWrapper}>
                    <div className={classes.brevet}>
                      <Grid
                        container
                        direction="row"
                        spacing={4}
                        alignItems="center"
                        className={classes.bRow}
                      >
                        <Grid item xs={5}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            className={classes.titre}
                          >
                            {b.titre}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <div
                            className={clsx(
                              classes.cell,
                              classes.cellSingleLine
                            )}
                          >
                            {b.date}
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            className={clsx(
                              classes.cell,
                              classes.cellSingleLine
                            )}
                          >
                            {b.no}
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            className={clsx(
                              classes.cell,
                              classes.cellSingleLine
                            )}
                          >
                            <img
                              src={b.timeSuccess ? TimeSuccess : Time}
                              alt={b.TimeSuccess}
                              style={{ height: 20, whidth: "auto" }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            className={clsx(classes.centered, {
              [classes.paddingLastRow]: matches,
              [classes.paddingRegularMobile]: !matches,
            })}
            direction="row"
            justify="center"
          >
            <Grid item xs={8}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                href={`/${langKey}/contact`}
              >
                {"Want to know more? Let’s met!"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default PublicationsEn;

export const query = graphql`
  query PublicationsQueryEn {
    allPublicationsJson {
      nodes {
        publications {
          en {
            mainTitle
            contribute {
              btnLabel
              content
              title
            }
            papers {
              acceptation
              authors
              content
              date
              object
              objectTr
              url
            }
            wePut {
              title
              content1
              content2
            }
            patents {
              content
              title
              items {
                date
                no
                timeSuccess
                titre
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
    publications: file(relativePath: { eq: "publications.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
